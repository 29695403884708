.deal-of-the-week {
    &.primary {
        background-color: $primary;

        @include media-breakpoint-up(lg) {
            background-color: transparent;
            background-image: linear-gradient($primary 0%, $primary 85%, transparent 85.1%);
        }
        
        @include media-breakpoint-up(xl) {
            background-color: transparent;
            background-image: linear-gradient($primary 0%, $primary 75%, transparent 75.1%);
        }
    }

    &.secondary {
        background-color: $secondary;

        @include media-breakpoint-up(lg) {
            background-color: transparent;
            background-image: linear-gradient($secondary 0%, $secondary 85%, transparent 85.1%);
        }
        
        @include media-breakpoint-up(xl) {
            background-color: transparent;
            background-image: linear-gradient($secondary 0%, $secondary 75%, transparent 75.1%);
        }
    }

    img {
        box-shadow: 0 0 0 0.25rem $white;
        object-fit: cover;
        max-height: 20rem;
        border-radius: $border-radius-lg;
    }
}
