@keyframes loader {
	0% {
		width: 0;
	}

	20% {
		width: 10%;
	}

	25% {
		width: 24%;
	}

	43% {
		width: 41%;
	}

	56% {
		width: 50%;
	}

	66% {
		width: 52%;
	}

	71% {
		width: 60%;
	}

	75% {
		width: 76%;
    
	}

	94% {
		width: 86%;
	}

	100% {
		width: 100%;
	}
}

.progress-bar {
	border-radius: 9rem;
	overflow: hidden;
    width: 100%;
    max-width: 40rem;
    margin: auto;

	span {
		display: block;
    }

    .bar {
        background: $light;
        position: relative;
        padding: .5rem;

        &::after {
            content: attr(data-status);
            position: absolute;
            color: $white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .progress {
        animation: loader 9s ease infinite;
        background: $primary;
        color: $white;
        padding: 1rem;
        width: 0;
        border-radius: 9rem;
    }
}
