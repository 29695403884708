@font-face {
    font-family: 'BreakFree Font';
    src: url('../../fonts/breakfree.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


[class*="icon-"] {
    // Defaults
    text-emphasis: none;
    font-family: 'BreakFree Font';
    font-style: normal;

    &[class*="circle-"] {
        color: $white;
        text-align: center;
        display: inline-block;
        padding: 0 .5rem;

        &::before {
            font-size: 90%;
        }
    }

    &.circle-primary {
        background-image: radial-gradient(circle closest-side, $primary 0, $primary 97%, transparent 100%);
    }

    &.circle-secondary {
        background-image: radial-gradient(circle closest-side, $secondary 0, $secondary 97%, transparent 100%);
    }

    &.circle-lighter {
        background-image: radial-gradient(circle closest-side, $lighter 0, $lighter 97%, transparent 100%);
    }

    &.circle-light {
        background-image: radial-gradient(circle closest-side, $light 0, $light 97%, transparent 100%);
    }

    &.circle-dark {
        background-image: radial-gradient(circle closest-side, $dark 0, $dark 97%, transparent 100%);
    }

    &.circle-darker {
        background-image: radial-gradient(circle closest-side, $darker 0, $darker 97%, transparent 100%);
    }

    &.circle-white {
        background-image: radial-gradient(circle closest-side, $white 0, $white 97%, transparent 100%);
    }
}

$icons: (
    amusements          : '\0040',
    attention           : '\006f',
    attention-alt       : '\0077',
    bar                 : '\0041',
    beach               : '\0042',
    bed                 : '\006d',
    cafe                : '\0043',
    calendar            : '\0069',
    camping             : '\0044',
    caravan             : '\0045',
    check               : '\004a',
    circle              : '\0070',
    circle-outline      : '\0071',
    close               : '\0046',
    close-alt           : '\003a',
    cottage             : '\0047',
    crazy-golf          : '\0048',
    disabled-access     : '\0049',
    down                : '\003d',
    facebook            : '\0065',
    ferry               : '\004b',
    golf                : '\004c',
    grid                : '\0073',
    gym                 : '\004d',
    hotel               : '\004e',
    indoor-pool         : '\004f',
    instagram           : '\0068',
    left                : '\003c',
    list                : '\0050',
    live-music          : '\0051',
    lodge               : '\0052',
    login               : '\0053',
    logo                : '\003f',
    map-marker          : '\0055',
    new-park            : '\0056',
    next                : '\005d',
    outdoor-pool        : '\0058',
    pet-friendly        : '\0059',
    phone               : '\0072',
    previous            : '\005e',
    restaurant          : '\005a',
    right               : '\003e',
    right-arrow         : '\0062',
    shop                : '\005b',
    sign-in             : '\005c',
    spa                 : '\0057',
    spinner-circle      : '\0029',
    spinner-dots        : '\0028',
    star                : '\0061',
    star-filled         : '\0061',
    star-half           : '\0064',
    star-outline        : '\0063',
    star-quater         : '\006a',
    star-three-quater   : '\006b',
    takeaway            : '\005f',
    tennis              : '\0060',
    twitter             : '\0066',
    up                  : '\003b',
    user                : '\006e',
    youtube             : '\0067',
    zoom                : '\0054'
);

@each $name, $char in $icons {
    .icon-#{$name}::before {
        content: $char;
    }
}
