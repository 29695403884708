.date-card {
  border: 0;
  @include site-box-shadow(darken($light, 5%));

  &.alt-card {
    margin: 0 1.25rem 1.25rem 0;
  }

  &.date-selected {
    @include site-box-shadow(rgba($primary, .25));
    .card-body {
      background-color: lighten( $primary, 60% ) !important;
      border-radius: 0 !important;
      p {
        color: $primary
      }
    }

    .card-footer {
      background-color: $primary;
      color: $white;
      border-top-color: $primary !important;
    }
  }
}