// NOTICE - Please do not import CSS files into here, as you will get ordering / specificity issues.
// CSS files should be imported in libs.scss instead. Thanks!

// Setup Bootstrap vars
@import './variables/bootstrap-vars';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Mixins
@import './mixins/Mixins';

// Bootstrap Class Overwrites
@import './partials/bootstrap-class-overwrites';

// BreakFree
@import './partials/icons';
@import './partials/custom';

// Components
@import './components/AccommodationListing';
@import './components/ColumnComponent';
@import './components/Countdown';
@import './components/DatePicker';
@import './components/DealOfTheWeek';
@import './components/Facilities';
@import './components/Footer';
@import './components/FormElements';
@import './components/Header';
@import './components/Hero';
@import './components/HolidayDateCard';
@import './components/Loading';
@import './components/ParkPageMenu';
@import './components/Review';
@import './components/SearchBox';
@import './components/SinglePark';
@import './components/Slider';
@import './components/TruncatedText';
@import './components/Videos';

// Pages
@import './pages/Booking';
@import './pages/SearchResults';
@import './pages/ChannelRegions';
@import './pages/Content';


// Freestylin' it...
.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        flex: 1;
    }
}

.remove-button-styling {
    all: unset; // be careful with this, it removes focus styles too...
}

.overflow-visible {
    overflow: visible !important;
}
