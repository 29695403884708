$hero-background-image: none !default;

.masthead-loading {
    min-height: 35rem;
    height: 60vh;
    max-height: 100vh;    
}

.masthead {
    @extend .masthead-loading;
    //background-image: $hero-background-image;
    background-color: $primary;
    background-size: cover;
    background-position: center 50%;
    background-repeat: no-repeat;

    [data-mh-lead],
    [data-mh-sub] {
        color: $white;
        text-shadow:
            0 .0625rem .125rem $darker,
            -1px -1px 0 rgba($dark, .25),
            1px -1px 0 rgba($dark, .25),
            -1px 1px 0 rgba($dark, .25),
            1px 1px 0 rgba($dark, .25);
    }

    [data-mh-lead] {
        font-size: 3rem;
    }

    [data-mh-sub] {
        font-size: 1.75rem;
    }

}

@include media-breakpoint-up(lg) {
    [data-mh-lead] {
        font-size: 5rem !important;
    }

    [data-mh-sub] {
        font-size: 2rem !important;
    }
}
