.column-image {
    object-fit: cover;

    &.fixed-image-height {
        height: 225px;
        @include media-breakpoint-up(md) {
            height: 275px;
        }
    }
}

.home-column {
    transition: transform .6s;

    &:hover {
        transform: translateY(-0.25rem);
        transition: transform .3s;
    }
}
