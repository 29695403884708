$header-background: darken($primary, 7%) !default;
$header-box-shadow: none !default;
$header-nav-toggle-color: $white !default;
$header-nav-link-color: $white !default;
$header-active-spot-color: $secondary !default;
$header-dd-color: inherit !default;
$header-dd-toggle-color: rgba($white, .33) !default;
$header-dd-hover-background: darken($primary, 10%) !default;
$header-dd-hover-color: $white !default;

$header-light-background: $white !default;
$header-light-box-shadow: 0 0.125rem 0.25rem rgba($dark, 0.125) !default;
$header-light-nav-toggle-color: $darker !default;
$header-light-nav-link-color: $dark !default;
$header-light-active-spot-color: $primary !default;
$header-light-dd-color: rgba($dark, .33) !default;
$header-light-dd-toggle-color: rgba($dark, .33) !default;
$header-light-dd-hover-background: $light !default;
$header-light-dd-hover-color: $dark !default;

$header-nav-link-padding-x: 1.5rem !default;
$header-static: true !default;

@mixin active-spot($colour, $placement: 'bottom') {
    @if ($placement == 'bottom') {
        transform: translateX(-0.166rem);
        bottom: -0.166rem;
        left: 50%;
    }
    @else if ($placement == 'left') {
        transform: translateY(0.25rem);
        bottom: 50%;
        left: .5rem;
    }

    background-color: $colour;
    content: '';
    display: block;
    position: absolute;
    border-radius: 1rem;
    width: 0.333rem;
    height: 0.333rem;
}

@mixin header-theme($background, $box-shadow, $nav-toggle-color, $nav-link-color, $active-spot-color, $dd-color, $dd-toggle-color, $dd-hover-background, $dd-hover-color) {
    background-color: $background !important;
    box-shadow: $box-shadow;

    .nav-link {
        color: $nav-link-color;

        &.active {
            &::after {
                @include active-spot($active-spot-color, 'bottom');
            }
        }
    }

    #nav-toggle {
        span,
        span::before,
        span::after {
            background: $nav-toggle-color
        }
    }

    .dropdown {
        .dropdown-toggle {
            &::after {
                color: $dd-toggle-color;
            }
        }

        .dropdown-menu {
            background-color: $background;
            color: $dd-color;

            .nav-link {
                &.active {
                    &::after {
                        @include active-spot($active-spot-color, 'left');
                    }
                }
            }

            .dropdown-item {
                &:hover {
                    color: $dd-hover-color;
                    background-color: $dd-hover-background;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    &.active {
                        &::after {
                            @include active-spot($active-spot-color, 'left');
                        }
                    }
                }
            }
        }
    }
}

#main-navigation {
    transition: background-color 0.25s;

    .nav-link {
        &.active {
            position: relative;
        }

        @include media-breakpoint-up(lg) {
            text-align: center;
        }
    }

    .navbar-brand {
        margin: 0;
    }

    .dropdown {
        .dropdown-toggle {
            position: relative;
            padding-right: 2rem;

            &::after {
                @include custom-font;
                content: '=';
                border: 0;
                position: absolute;
                right: .666rem;
                transform: translateY(.05rem);
            }

            &[aria-expanded="true"] {
                &::after {
                    content: ':';
                }
            }
        }

        .dropdown-menu {
            .nav-link {
                padding-left: $header-nav-link-padding-x;
                padding-right: $header-nav-link-padding-x;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }
        }
    }

    // Hamburger menu
    #nav-toggle {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        cursor: pointer;
        outline: none;

        span {
            transform: translateX(12px);
            &::before {
                top: -9px;
                transform: rotate(0);
            }
            &::after {
                bottom: -9px;
                transform: rotate(0);
            }
        }

        span,
        span::before,
        span::after {
            cursor: pointer;
            height: 2px;
            width: 26px;
            background: $white;
            position: absolute;
            display: block;
            content: '';
            transition: all 0.3s ease-in-out;
        }

        &:not(.collapsed) {
            span {
                background-color: transparent !important;
                &::before {
                    transform: rotate(45deg);
                    transform-origin: top left;
                }
                &::after {
                    transform: rotate(-45deg);
                    transform-origin: bottom left;
                }
            }
        }
    }

    // Primary (dark) theme
    @include header-theme(
        $header-background,
        $header-box-shadow,
        $header-nav-toggle-color,
        $header-nav-link-color,
        $header-active-spot-color,
        $header-dd-color,
        $header-dd-toggle-color,
        $header-dd-hover-background,
        $header-dd-hover-color,
    );

    // Light theme
    //@if not $header-static {
        &[data-style='light'] {
            @include header-theme(
                $header-light-background,
                $header-light-box-shadow,
                $header-light-nav-toggle-color,
                $header-light-nav-link-color,
                $header-light-active-spot-color,
                $header-light-dd-color,
                $header-light-dd-toggle-color,
                $header-light-dd-hover-background,
                $header-light-dd-hover-color,
            );
        }
    //}
}

@include media-breakpoint-down(md) {
    #main-navigation {
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    padding-left: 1.5rem;
                }
            }
        }

        .dropdown-menu{
            &.show {
                border: none;
            }
        }
    }
}

/* Mega Menu
.mega-menu-dropdown {
    .dropdown-menu {
        width: 100%;
    }
}

.mega-menu {
    &[data-style="dark"] {
        color: $light;

        .show-primary-line {
            border-top: 1px solid $darker;
        }

        a {
            color: $white;;
        }

        button {
            &[aria-controls^="collapse-"] {
                color: $white;
            }
        }
    }
    flex-wrap: wrap;

    .show-primary-line {
        border-top: 1px solid $light;
    }

    button {
        &[aria-controls^="collapse-"] {
            text-decoration: none;
            color: $darker;
            border: 0;
            border-radius: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            box-shadow: none;
            transition: box-shadow 1s;

            &:focus {
                box-shadow: none;
            }

            &[aria-expanded="true"] {
                box-shadow: 0 -.33rem 0 0 $primary inset;
                transition: box-shadow .5s;
                //transform: translateY(.25rem);
                
                &::after {
                    //content: '';
                    //display: block;
                    //width: 1rem; 
                    ////height: .5rem; 
                    //clip-path: polygon(0 20%, 5% 5%, 20% 0, 80% 0, 95% 5%, 100% 20%, 60% 95%, 50% 100%, 40% 95%);
                    //background-color: $primary;
                    //position: relative;
                    //left: 50%;
                    //transform: translate(-.5rem, .5rem);
                }
            }
        }
    }
    
    [id^="collapse-"] {
        flex: 0 0 100%;
    }
}
.mega-menu-panel {
    padding: ($spacer * 2) $spacer;
    margin: 0 auto;

    ul {
        li {
            font-size: .875rem !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .mega-menu {
        button {
            &[aria-controls^="collapse-"] {
                text-align: left;
                font-size: 1rem;
                line-height: 2rem;
            }
        }

        .mega-menu-panel {
            position: fixed;
            top: 0;
            background-color: white;
            left: 0;
            max-height: 100vh;
            overflow: auto;
            margin-top: 0;
        }

        .close-mega-menu-panel {
            position: fixed;
            top: 2rem;
            right: 1rem;
            z-index: 1;
        }
    }
}

*/

// New
#main-navigation {
    @include media-breakpoint-down(md) {
        max-height: 100vh;
        overflow-y: auto;
    }

    .megamenu {
        position: static;

        .dropdown-toggle {
            transition: box-shadow 1s;
            padding-left: 0 !important;
            margin-left: 1rem;

            &[aria-expanded="true"] {
                box-shadow: 0 -.125rem 0 0 $primary inset;
                transition: box-shadow .5s;
            }
        }

        .dropdown-menu {
            background-color: transparent !important;
            border: none;
            width: 100%;
            padding: 0;

            .megamenu-panel {
                //@include site-box-shadow(rgba($dark, .25));
                box-shadow: 0 .5rem 0.5rem rgba($dark, .25);
                background-color: $white;
                transition: background-color .25s;
                position: relative;

                @include media-breakpoint-down(md) {
                    box-shadow: none;
                }

                &::after, &::before {
                    height: 1rem;
                    width: 1rem;
                    background-color: $white;
                    content: '';
                    position: absolute;
                    top: 0;
                    transition: background-color .25s;
                }

                @if $border-radius > .125rem {
                    &::after {
                        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 97% 75%, 93% 63%, 88% 53%, 82% 43%, 75% 34%, 66% 25%, 57% 18%, 46% 11%, 32% 5%, 15% 1%);
                        left: 0;
                        transform: translate(-.98rem, 0);
                    }

                    &::before {
                        clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 85% 1%, 75% 3%, 64% 7%, 51% 13%, 40% 20%, 29% 29%, 20% 40%, 11% 54%, 5% 69%, 2% 84%);
                        right: 0;
                        transform: translate(1rem, 0);
                    }
                }

                .megamenu-heading {
                    color: $dark;
                }
            }
        }


    }

    &[data-style="dark"] {
        .megamenu {
            .dropdown-toggle {
                &[aria-expanded="true"] {
                    box-shadow: 0 -.125rem 0 0 $megamenu-dark-link-colour inset;
                }
            }
            .megamenu-panel {
                background-color: $header-background;
                transition: background-color .25s;

                &::before, &::after {
                    background-color: $header-background;
                    transition: background-color .25s;
                }

                .megamenu-heading {
                    color: $white;
                }

                a {
                    color: $megamenu-dark-link-colour;
                }
            }
        }
    }    
}
