// Typography
//h1 {line-height: $font-size-base * 2.75;}
//h2 {line-height: $font-size-base * 2;}
//h3 {line-height: $font-size-base * 1.875;}
//h4 {line-height: $font-size-base * 1.5;}

// Accordion
.accordion {

    &.dark {
        .card {

            border-color: $dark;
            border-radius: $border-radius;

            .card-header {
                background-color: lighten($dark, 7.5%);
                color: $white;
            }

            .card-body {
                background-color: lighten($dark, 15%);
                color: $white;
            }

            &.active {
                .card-header {
                    position: relative;
                    background-color: $darker;
                    border-color: lighten($dark, 5%);
                    color: $white;

                    &::after {
                        content: '-';
                    }

                }
            }
        }
    }

    .card {
        margin: 0;

        .card-header {
            cursor: pointer;
            font-size: $font-size-base * 1.125;

            &::after {
                content: '+';
                display: inline-block;
                font-weight: bold;
                position: absolute;
                right: 1rem;
            }

            &:hover {
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }
        }

        &:not(.active):hover {
            border-color: $primary;
        }

        &.active {

            .card-header {
                position: relative;
                background-color: $dark;
                border-color: $dark;
                color: $white;

                &::after {
                    content: '-';
                }

            }
        }
    }
}

// Alerts
.alert-dismissible .close {
    padding: 0.5rem 1.25rem;
    opacity: 1;
}

.alert-success {
    &.light {
        background-color: lighten($success, 50%);
        border: 0;
    }
}

.alert-danger {
    &.light {
        background-color: lighten($danger, 50%);
        border: 0;
    }
}

// Buttons
.btn {
    // &.btn-secondary {
    //     color: $white;
    // }

    // &.btn-light,
    // &.btn-outline-light {
    //     color: $dark;
    // }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }
}

// Cards
.card {
    @include result-box;
    margin-bottom: 1.9rem;

    .card-img {
        border-radius: 0;

        //&:not(.card-img-top),
        //&:not(.card-img-bottom) {
        //    border-radius: 0;
        //}
    }

    .card-body {
        .card-header-tabs {
            margin-right: 0;
            margin-bottom: 0.375rem;
            margin-left: 0;
            border-bottom: 1px solid $light;
        }

        .card-header-pills {
            margin-right: 0;
            margin-bottom: 0.375rem;
            margin-left: 0;

            &.full-width {
                display: flex;
                flex-wrap: nowrap;

                .nav-item {
                    width: 100%;
                    text-align: center;
                }
            }

            .nav-item {
                border: 1px solid $primary;
                margin: 0 0.375rem;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

// Carousel
.carousel {
    &.full-height {
        .carousel-inner {
            height: 100%;
        }
    }
}

// Form Control
.custom-switch {
    .custom-control-label {
        user-select: none;
        cursor: pointer;

        &::before {
            border: 1px solid darken($light, 20%);
        }

        &::after {
            background-color: darken($light, 20%);
        }
    }
}
//.form-control {
//    height: calc(1.375em + 1.4rem + 2px);
//}

// Pills
.nav {
    &.nav-alt {
        background-color: $primary;
        border-top: .5rem solid $primary;
        border-bottom: .5rem solid $primary;

        .nav-item {
            a {
                border-radius: 0;
                color: $white;
                padding: 1rem;
                position: relative;

                &::after {
                    content: '\003e';
                    font-family: 'Breakfree Font';
                    display: inline-block;
                    position: absolute;
                    right: 1rem;
                }

                &.active {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
    }
}

// Tables
table {
    &.price-breakdown {
        margin-bottom: 0;
        tr {
            border-color: $light;

            &:first-of-type {
                border-top: 0;
            }

            &:nth-of-type(even) {
                background-color: $lighter;
            }

            td {
                padding: .4rem 0;
            }
        }
    }
}
