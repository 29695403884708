.slick-prev,
.slick-next {
    height: 3rem;
    width: 3rem;
    z-index: 1;

    &::before {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 3rem;
    }
}

.slick-prev {
    left: .5rem;
    &::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNjQgNjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDY0IDY0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7b3BhY2l0eTowLjU7fQ0KCS5zdDF7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIzMiIgY3k9IjMyIiByPSIzMiIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTMyLDU2YzEzLjMsMCwyNC0xMC43LDI0LTI0UzQ1LjMsOCwzMiw4UzgsMTguNyw4LDMyUzE4LjcsNTYsMzIsNTZ6IE0xNS4zLDMxLjhDMTUuMywzMS44LDE1LjMsMzEuNywxNS4zLDMxLjgNCgljMC0wLjEsMC0wLjEsMC0wLjJjMCwwLDAtMC4xLDAtMC4xczAtMC4xLDAtMC4xYzAsMCwwLTAuMSwwLTAuMXMwLTAuMSwwLTAuMWMwLDAsMC0wLjEsMC0wLjFjMCwwLDAtMC4xLDAtMC4xYzAsMCwwLTAuMSwwLjEtMC4xDQoJYzAsMCwwLTAuMSwwLjEtMC4xYzAsMCwwLTAuMSwwLjEtMC4xYzAsMCwwLDAsMC4xLTAuMWMwLTAuMSwwLjEtMC4xLDAuMS0wLjFsMTEuNC0xMS40YzAuNy0wLjgsMi0wLjcsMi43LDBjMC44LDAuNywwLjgsMiwwLDIuNw0KCWwtOC4xLDguMWgyNC45YzEuMSwwLDEuOSwwLjksMS45LDEuOXMtMC45LDEuOS0xLjksMS45SDIxLjlMMzAsNDJjMC44LDAuNywwLjcsMiwwLDIuN2MtMC43LDAuOC0yLDAuOC0yLjcsMEwxNS45LDMzLjRjMCwwLDAsMCwwLDANCgljMCwwLTAuMS0wLjEtMC4xLTAuMWMwLDAsMC0wLjEtMC4xLTAuMWMwLDAsMC0wLjEtMC4xLTAuMWMwLDAsMC0wLjEtMC4xLTAuMXMwLTAuMSwwLTAuMWMwLDAsMC0wLjEsMC0wLjFjMCwwLDAtMC4xLDAtMC4xDQoJYzAsMCwwLTAuMSwwLTAuMWMwLDAsMC0wLjEsMC0wLjFjMCwwLDAtMC4xLDAtMC4xczAtMC4xLDAtMC4xYzAsMCwwLTAuMSwwLTAuMWMwLDAsMC0wLjEsMC0wLjFjMC0wLjEsMC0wLjEsMC0wLjINCglTMTUuMywzMS45LDE1LjMsMzEuOHoiLz4NCjwvc3ZnPg0K');
    }
}

.slick-next {
    right: .5rem;
    &::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNjQgNjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDY0IDY0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7b3BhY2l0eTowLjU7fQ0KCS5zdDF7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIzMiIgY3k9IjMyIiByPSIzMiIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTMyLDhDMTguNyw4LDgsMTguNyw4LDMyczEwLjcsMjQsMjQsMjRzMjQtMTAuNywyNC0yNFM0NS4zLDgsMzIsOHogTTQ4LjcsMzIuMkM0OC43LDMyLjIsNDguNywzMi4zLDQ4LjcsMzIuMg0KCWMwLDAuMSwwLDAuMSwwLDAuMmMwLDAsMCwwLjEsMCwwLjFjMCwwLDAsMC4xLDAsMC4xYzAsMCwwLDAuMSwwLDAuMWMwLDAsMCwwLjEsMCwwLjFjMCwwLDAsMC4xLDAsMC4xYzAsMCwwLDAuMSwwLDAuMQ0KCWMwLDAsMCwwLjEtMC4xLDAuMWMwLDAsMCwwLjEtMC4xLDAuMWMwLDAsMCwwLjEtMC4xLDAuMWMwLDAsMCwwLTAuMSwwLjFjMCwwLjEtMC4xLDAuMS0wLjEsMC4xTDM2LjgsNDQuN2MtMC43LDAuOC0yLDAuNy0yLjcsMA0KCWMtMC44LTAuNy0wLjgtMiwwLTIuN2w4LjEtOC4xSDE3LjJjLTEuMSwwLTEuOS0wLjktMS45LTEuOXMwLjktMS45LDEuOS0xLjloMjQuOUwzNCwyMmMtMC44LTAuNy0wLjctMiwwLTIuN2MwLjctMC44LDItMC44LDIuNywwDQoJbDExLjQsMTEuNGMwLDAsMCwwLDAsMGMwLDAsMC4xLDAuMSwwLjEsMC4xYzAsMCwwLDAuMSwwLjEsMC4xYzAsMCwwLDAuMSwwLjEsMC4xYzAsMCwwLDAuMSwwLjEsMC4xYzAsMCwwLDAuMSwwLDAuMQ0KCWMwLDAsMCwwLjEsMCwwLjFjMCwwLDAsMC4xLDAsMC4xYzAsMCwwLDAuMSwwLDAuMWMwLDAsMCwwLjEsMCwwLjFjMCwwLDAsMC4xLDAsMC4xYzAsMCwwLDAuMSwwLDAuMWMwLDAsMCwwLjEsMCwwLjENCgljMCwwLDAsMC4xLDAsMC4xYzAsMC4xLDAsMC4xLDAsMC4yQzQ4LjcsMzIuMSw0OC43LDMyLjEsNDguNywzMi4yeiIvPg0KPC9zdmc+');
    }
}

.homepage-slide {
    .slick-track{
        display: flex;
    }
    .slick-slide {
        height: inherit;
        padding: .5rem;
        > div {
            display: flex;
            height: 100%;
        }
    }

    .slick-prev {
        transform: translate(1rem, -5.5rem);
    }

    .slick-next {
        transform: translate(-1rem, -5.5rem);
    }
}

// Park page mods to help with Videos
.slick-slider {
    &.full-height-slides {
        .slick-track {
            display: flex;
            flex-direction: row;

            .slick-slide {
                display: flex;
                height: inherit;

                div {
                    height: inherit;
                    width: 100%;

                    iframe {
                        width: 100%;
                        height: 100%;
                        border: 0;
                    }
                }
            }
        }
    }
}
