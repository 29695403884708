$form-label-margin-bottom: 0.5rem !default;

.form-elements-wrapper {
    display: inline-block;
    width: 225px;

    &--can-grow {
        min-width: 225px;
        width: auto;
    }
}

label,
.form-label {
    margin-bottom: $form-label-margin-bottom;
}

// Input-group
.inputRadioGroup {
    &.invalid, &.is-invalid {
        .input-group-text {
            background: rgba($form-feedback-invalid-color, 0.25);
        }
        .input-group-text, label {
            border-color: $form-feedback-invalid-color;
            color: $form-feedback-invalid-color;
        }
    }
}

.paypal-field {
    font-family: $font-family-safe; // We cant load custom fonts for paypal fields, so make the other fields match
}

.braintree-hosted-fields-focused {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
}

.DayPickerInput {
    display: block;
}
