.countdown-card {
    //@include site-box-shadow(darken($light, 5%));
    border: 1px solid darken($light, 5%);
    color: $dark;

    strong {
        font-size: 3rem;
        @include media-breakpoint-only(xs) {
            font-size: 2rem;
        }
    }
}

