.accommodation-listing {
    position: relative;

    .accommodation-listing-remaining {
        border-bottom-right-radius: ($border-radius * 1.5);
        font-weight: bold;
        text-shadow: 0 .0625rem .0625rem darken($secondary, 15%);
    }

    .accommodation-listing-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .accommodation-listing-remaining {
            position: absolute;
		    	  right: auto;
            left: 0;
            border-top-left-radius: $border-radius;
        }
    }

    .accommodation-listing-content {
        width: 50%;

        .saving {
            color: $green;
        }

        button {
            width: 150px;
        }
    }
}

@include media-breakpoint-only(xs) {
    .accommodation-listing {
        .accommodation-listing-image,
        .accommodation-listing-content {
            width: 100%;
            min-height: 8rem;
        }
    }
}
