.truncated-text-alt {
    .copy {
        display: -webkit-box;
        -webkit-line-clamp: 99;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        white-space: pre-line;

        & ~ button {
            display: none;
        }

        &.truncated {
            -webkit-line-clamp: 3;

            & ~ button {
                display: inline-block;
            }
        }
    }    
}