.facilities {

  .facility-icon {
    width: 80px;
  }

  .facilities-list {
    padding: 0;
    list-style: none;
  }
}
