// Grid
$container-max-widths: (
  xl: 1400px
) !default;

// Z Index
$zindex-dropdown:           998;
$zindex-sticky:             999;

$grid-gutter-width:         2rem !default;

// Spacer
$spacer:                    1rem !default;

// Typography
$small-font-size:           80% !default;
$line-height-base:          1.5 !default;
$h1-font-size:              $font-size-base * 2.625 !default;
$h2-font-size:              $font-size-base * 1.75 !default;
$h3-font-size:              $font-size-base * 1.625 !default;
$h4-font-size:              $font-size-base * 1.375 !default;
$h5-font-size:              $font-size-base * 1.125 !default;
$h6-font-size:              $font-size-base !default;
$headings-line-height:      1.25 !default;
$headings-color:            $darker !default;
$headings-font-weight:      300 !default;
$text-muted:                lighten($dark, 45%) !default;

// Alerts
$alert-bg-level:            0 !default;
$alert-border-level:        0 !default;
$alert-color-level:         10 !default;

// Border Radius
$border-radius:             0 !default;
$border-radius-lg:          0 !default;
$border-radius-sm:          0 !default;

// Buttons
$input-btn-font-size:       $font-size-base * 1.25 !default;
$btn-border-radius:         $border-radius !default;
$input-btn-padding-y:       .5rem !default;
$btn-disabled-opacity:      .33 !default;

// Cards
$card-border-color:         $light !default;
$card-cap-bg:               $lighter !default;
$card-spacer-y:             .75rem !default;
$card-spacer-x:             .75rem !default;
$card-border-radius:        $border-radius-lg !default !default;

// Close
$close-font-size:           $font-size-base * 1.625 !default;
$close-font-weight:         normal !default;
$close-color:               $darker !default;
$close-text-shadow:         none !default;

// Code
$kbd-bg:                    transparentize($dark, .85) !default;

// Dropdown
$dropdown-divider-bg:       rgba($black, .15) !default;

// Forms
$input-font-size:           $font-size-base !default;
$input-padding-y:           $input-btn-padding-y + .2 !default;
//$input-padding-x:         $input-btn-padding-x !default !default;
$form-check-input-margin-y: .375rem !default;

// hr
$hr-border-color:           $light !default;

// Jumbotron
$jumbotron-bg:              $light !default;

// Modal
$modal-backdrop-bg:         $darker !default;
$modal-backdrop-opacity:    .9 !default;
$zindex-modal-backdrop:     99999 !default;
$zindex-modal:              99999 !default;

// Navbar
$navbar-padding-y:          $spacer !default;
$navbar-light-color:        darken($primary, 7%) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$white}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  transparent !default;
$dropdown-link-active-bg:           transparent !default;
$megamenu-dark-link-colour: $secondary !default;

// Tooltips
$tooltip-color:                     $white !default;
$tooltip-bg:                        $darker !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 .375rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 0 .25rem !default;
$tooltip-arrow-width:               1rem !default;
$tooltip-arrow-height:              .5rem !default;
