.channel-regions-page {
    .col {
        display: flex;
    }
    
    .card-body {
        display: flex;
        flex-direction: column;

        .text-dark {
            flex-grow: 1;
        }
    }
}
