.wp-block-columns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    width: 100%;

    .wp-block-column {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        a.btn {
            margin-top: auto;
        }
    }
}

@include media-breakpoint-up(md) {
    .wp-block-columns {
        display: flex;
        flex-wrap: nowrap;

        .wp-block-column {
            min-width: 0;
            word-break: break-word;
            overflow-wrap: break-word;
            flex-basis: 0;
            flex-grow: 1;

            &:not(:first-child) {
                margin-left: $grid-gutter-width;
            }
        }
    }
}

// There is no variable for pure gray which is 128,128,128 or #808080, so I left it as a gray

table {
    margin-bottom: $spacer;
    width: 100%;
    border-spacing: 2px;
    border-color: gray;

    thead, tfoot{
        color: $gray-900;
    }

    tfoot {
        font-weight: $font-weight-bold;
    }

    th, tr {
        border-top: $border-width solid $gray-300;
    }

    th {
        border-bottom: ($border-width * 2) solid $gray-300;
    }
}

.wp-block-image {
    img {
        @include img-fluid;
    }
}

.wp-block-gallery {
    display: block;

    .blocks-gallery-grid {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;
        margin: 0;

        .blocks-gallery-item {
            margin: 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2) 0;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            position: relative;

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            img {
                display: block;
                max-width: 100%;
                height: auto;
                width: 100%;
            }

            figure {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                margin: 0;
                height: 100%;
            }
        }
    }

    &.is-cropped {
        img {
            height: 100% !important;
            flex: 1;
            object-fit: cover;
        }
    }

    &.columns-2 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(50% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }

    &.columns-3 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(33.33333% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }

    &.columns-4 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(25% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }

    &.columns-5 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(20% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }

    &.columns-6 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(16.6666% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }

    &.columns-7 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(14.2857% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }

    &.columns-8 {
        .blocks-gallery-grid {
            .blocks-gallery-item {
                width: calc(12.5% - #{$grid-gutter-width / 2});
                margin-right: ($grid-gutter-width / 2);
            }
        }
    }
}

.wp-block-cover {
    position: relative;
    background-size: cover;
    background-position: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;

    &.has-background-dim {
        background-color: $black;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            opacity: .5;
            content: "";
            background-color: inherit;
        }
    }

    .wp-block-cover__inner-container {
        z-index: 1;
        color: #fff;

        p {
            text-align: center;
            color: white;
            padding: 10rem 0;
            margin: 0;
            font-size: 2rem;
        }
    }
}



