.search-box {
    .form-control {
        // Next two put on to make nights select prettier

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($primary, 0.66);
        }
    }

    .search-dropdown {
        button {
            border-color: $white;
            padding: 0.7rem 0.75rem !important;
            font-size: 1rem;
        }

        &.wide {
            .dropdown-menu {
                width: 100vw;
                min-width: 20rem;
                max-width: 40rem;
            }
        }
    }

    .pet-friendly {
        color: $white;
        font-weight: bold;
    }
}

/* New Search box */
.toggle-popup {
    display: block;
    width: 100%;
    text-align: left;
    border: 0;
    height: 46px;
    background-color: $white;
    line-height: 1.75;
    font-size: 1rem;
    border-radius: $border-radius;
    padding: calc(0.5rem + 1px) 0.75rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        color: $white;
        cursor: not-allowed;
        background-color: rgba($black, .075);

        span {
            &::after {
                background-color: transparent;
                color: rgba($white, .25);
            }
        }
    }

    span {
        display: block;
        position: relative;
        overflow: hidden;

        &::after {
            // The next line changes the equals to a large arrow
            font-family: 'BreakFree Font';
            font-size: 1.25rem;
            line-height: 24px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $white;
            border-left: .25rem solid $white;
        }
    }
}

.is-calendar {
    span {
        &::after {
            content: 'i';
        }
    }
}

.is-dropdown {
    span {
        &::after {
            content: '=';
        }
    }
}

.toggle-popup {
    color: $dark;

    // This next line used to be .aria-expanded
    &[aria-expanded='true'] {
        span {
            &::after {
                content: ':';
            }
        }
    }
}

.a-popup {
    display: block;
    position: absolute;
    z-index: 2;
    background-color: $white;
    transform: translateY(1rem);
    width: 320px;
    min-height: 100px;
    max-height: 320px;
    box-shadow: 0 0 1rem rgba($black, 0.25);
    border-radius: $border-radius;
    overflow: hidden;
    overflow-y: auto;

    &.is-closed {
        display: none;
    }

    &.is-open {
        display: block;
    }

    &.no-max-height {
        max-height: fit-content;
    }
}

// Used to remove default arrow on dropdown
.dropdown-toggle::after {
    content: none;
}

.popup-search {
    border: 1px solid #ccc;
    background: white;
    width: 100%;
    display: block;
    border-radius: $border-radius;

    &::after {
        font-family: 'BreakFree Font';
        font-size: 1.25rem;
        content: 'T';
        float: right;
        transform: translate(-0.3rem, 0.3rem);
    }

    input[type='search'] {
        width: 90%;
        border: 0;
        padding: 0.5rem 0.75rem;
        background: transparent;
        outline: none;
    }
}

.popup-select {
    overflow-y: scroll;
    max-height: 200px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 0.5rem;
            color: $dark;

            &:hover {
                background-color: $primary;
                color: $white;
                cursor: pointer;
            }
        }
    }
}

#search-modal {
    &.is-open {
        display: flex !important;
        height: 100vh;
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.975);
        position: fixed;
        top: 0;
        left: 0;
        padding: 3rem 1rem 1rem;
        margin: 0;
        z-index: 1000;
        overflow-y: auto;

        label {
            color: $darker !important;
        }

        .toggle-popup {
            border: 1px solid $light;
        }

        .close-search-modal {
            position: absolute;
            top: 1rem;
            right: 1.5rem;
            cursor: pointer;
        }
    }

    select {
        &[multiple] {
            height: 200px;
        }
    }

    .bf-radio {
        padding: 0.5rem 0;
        margin-left: 1rem;

        input,
        label {
            cursor: pointer;
        }

        input {
            &[type='radio'] {
                -webkit-appearance: none;
                appearance: none;
                outline: none;
                margin: 0;
                position: relative;

                &::before {
                    @include custom-font;
                    content: 'q';
                    color: $light;
                    transform: translateY(-0.333rem);
                    display: inline-block;
                    transition: color 0.25s;
                }

                &:checked {
                    &::before {
                        content: 'p';
                        color: $primary;
                        transition: color 0.75s;
                    }
                }
            }
        }

        label {
            margin-left: 0.5rem;
            transform: translateY(-0.28rem);
        }
    }
}

@media screen and (max-width: 767px) {
    .a-popup {
        position: fixed;
        box-sizing: border-box;
        width: 100vw;
        border-radius: 0;
        left: 0;
        max-height: 264px;

        &#guests-select {
            transform: translate(-16px, -200px) !important;
        }
    }

    .search-box {
        .pet-friendly {
            $color: $darker;
            font-weight: normal;
        }
    }

    .toggle-popup {
        &:disabled {
            color: $body-color;
        }
    }
}
