// ********************
// *     Branding     *
// ********************

// Colours
$primary        : #438319;
$secondary      : #f08000;
$warning        : #fde8be;

$grey-dark      : #5B5B5A;
$dark           : $grey-dark;
$darker         : darken($dark, 12%);

$light-grey     : #ededed;
$light          : $light-grey;
$lighter        : lighten($light, 5%);

$red            : #D13C1B;
$green          : #74b62e;

$white          : #fff;
$black          : #000;

$theme-colors: (
    "lighter" : $lighter,
    "darker" : $darker
);

// Fonts
@import url("https://use.typekit.net/hvf7bsp.css");
$headings-font-weight: 500;

$body-color:                $dark;
$font-size-base:            1rem;
$font-family-safe:          'titillium-web', sans-serif;
$font-family-sans-serif:    'titillium-web', sans-serif;

$link-color:                $secondary;

// Border Radius
$border-radius:             .33rem;
$border-radius-lg:          .5rem;
$border-radius-sm:          .25rem;

// Header
$header-background: $dark;
$header-static: false; // Rename this, does not apply to 'static' positioning but colour change on scroll.
$header-nav-link-padding-x: 1rem;

// Hero
$hero-background-image: url('https://content.breakfreeholidays.co.uk/images/homepage/hero.jpg'); // must be relative to _hero.scss

// Forms
$form-label-margin-bottom: 0.1rem;

// Footer
$footer-nav-bg-color: $dark;
$footer-trust-bg-color: $light;

// ***************
// *     App     *
// ***************

@import './base/assets/scss/app.scss';

// Overwrites
.deal-of-the-week {
    background-image: linear-gradient($black 0%, $black 75%, transparent 75.1%);
}

.header-bar {
    background-color: $black !important;
    color: $white;
    a,
    button {
        color: $white !important;
    }
}

.footer-nav-heading {
    color: $white !important;
    font-weight: bold;
}

#newsletter {
    background-color: $lighter !important;
    color: $black !important;
    border-top: 1px solid $light;

    h2.text-white {
        color: $black !important;
    }

    p a {
        color: $secondary !important;
    }

    .form-control {
        border: 1px solid $light !important;
    }
}

.badge-secondary {
    color: $white !important;
}
