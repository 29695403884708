.vertical-tab-menu {
    padding: 0;
    list-style: none;
    @include result-box;

    & li {
        color: $dark;
        border-bottom: 1px solid $light;
      
        @include media-breakpoint-only(md) {
          font-size: 1rem;
        }
      
        &:first-of-type {
          border-radius: $border-radius $border-radius 0 0;
        }
      
        &:last-of-type {
          border-radius: 0 0 $border-radius $border-radius;
        }

        &:hover,
        &.active {
            color: $white;
            background-color: $primary;
            border-bottom: 1px solid $primary;
        }
    }
}

// Small device {menu
.vertical-tab-menu-sm {
    .toggle-popup {
        height: auto;
        font-size: 1.25rem;
    }

    .dropdown-menu {
        width: 100%;
        font-size: 1.25rem;
    }
    .dropdown-item {
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}