$result-box-border-color: $light !default;
$site-box-shadow-color: $light !default;

@mixin site-box-shadow($colour: $site-box-shadow-color) {
    box-shadow: 0 .25rem .5rem $colour;
}

@mixin result-box {
    border: 1px solid $result-box-border-color;
    border-radius: $border-radius;
    margin-bottom: 1rem;
    overflow: hidden;
    @include site-box-shadow;
}

@mixin custom-font {
    font-family: 'BreakFree Font';
}
