.DayPicker {
    width: 100%;

    .DayPicker-wrapper {
        padding-bottom: 0;

        .DayPicker-NavBar {
            .DayPicker-NavButton {
                top: 0;
                right: 0;
            }
        }
    }

    &.alt-cal {
        .DayPicker-Day {
            width: calc(100% / 7);
            vertical-align: top;

            .alt-cal-cell {
                font-weight: bold;
                overflow: hidden;

                .alt-cal-cell-date {
                    color: darken($light, 20%);

                    font-size: 1rem;
                    padding: .75rem;
                    
                }

                .alt-cal-cell-price {
                    padding: .5rem;
                }

                &.has-price {
                    background-color: $white;
                    border-radius: $border-radius;
                    @include site-box-shadow;

                    &:hover {
                        @include site-box-shadow(rgba($primary, .25));
                        cursor: pointer;

                        .alt-cal-cell-price {
                            background-color: $primary;
                            color: $white;
                        }
                    }

                    .alt-cal-cell-date {
                        color: $dark;
                    }

                    .alt-cal-cell-price {
                        background-color: $lighter;
                        color: $dark;
                    }
                }
            }
        }
    }
}

.DayPicker-Month {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    .DayPicker-Caption {
        padding: 0;
    }
}

.DayPicker-Day {
    border-radius: $border-radius;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $primary;

    &:hover {
        background-color: $primary;
    }

}


